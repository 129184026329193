import { render, staticRenderFns } from "./AdminEvents.html?vue&type=template&id=7abf4ab3&scoped=true&"
import script from "./AdminEvents.vue?vue&type=script&lang=js&"
export * from "./AdminEvents.vue?vue&type=script&lang=js&"
import style0 from "./AdminEvents.scss?vue&type=style&index=0&id=7abf4ab3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7abf4ab3",
  null
  
)

export default component.exports